@import '../../styles/variables.scss';

.wrapper {
  @include wrapper;
  background-color: $light-blue;
}

// .my_kids_wrapper {
//   @include contentWrapper;
// }

.screenTitle {
  @include screenTitle;
}

.giftCardInfo {
  color: $text-dark;
  font-family: 'ProximaNova';
  border-color: $light-grey-border;
}

.assignModal {
  background-color: $lightblue-color;
}

.children_wrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
}

.child_detail_wrapper {
  margin: 0 10px;
}

.submit_content {
  @include submitContent;
}

.save_button {
  text-align: center;
}



// .my_kids_wrapper {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   padding: 30px 32px 20px;
//   max-width: 500px;
//   width: 100%;
//   margin: 0 auto;
// }

.child_detail_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.view_child_timeline_btn {
  margin-top: 24px;
  border-radius: 24px;
  height: 50px;
  width: 165px;
  font-family: 'ProximaNova Bold';
  font-size: 19px;
  background-color: $blue-bg;
  color: $white;

  &:hover,
  &:active,
  &:focus {
    background-color: $darkblue-bg;
    color: $white;
    text-decoration: underline;
  }
}
.add_child {
  display: flex;
  align-items: center;
  color: $darkblue-bg;
  margin: 34px 0 64px;

  & div {
    font-family: 'ProximaNova Bold';
    font-size: 20px;
  }

  .add_child_button {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $blue-bg;
    border: 2px solid $blue-bg;

    & :global(span.anticon) {
      font-size: 12px;
      font-weight: bold;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
