@import '../../styles/variables.scss';

$transition-speed: 0.6s;
$left-margin: 20px;
$break-point: 768px;

@mixin aspect-ratio($width, $height) {
  aspect-ratio: $width / $height;
  // Fallback (current, using padding hack)
  @supports not (aspect-ratio: 1 / 1) {
    &::before {
      float: left;
      padding-top: calc(100% * #{$height} / #{$width});
      content: '';
    }
    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }
}

.wrapper {
  min-height: 100vh;
  overflow-y: auto;
  padding: 0;
  max-width: 100%;
  width: 100%;
}

.stickyHeaderContainer {
  z-index: 5000;
}

.lowerHeaderActive {
  transition: all $transition-speed;
  .kid_timline_wrapper {
    max-height: 140px;
    overflow: hidden;
    transition: all $transition-speed;
  }
  .badge {
    transition: all $transition-speed;
    width: 50px;
    height: 48px;
    font-size: 0;
  }
  .child_margin {
    transition: all $transition-speed;
    width: 50px;
    height: 48px;
  }

  button svg {
    width: 0 !important;
    transition: all $transition-speed;
  }

  :global(.ant-btn) {
    display: none;
  }

  :global(.ant-badge) > img {
    transition: all $transition-speed;
    width: 50px;
    height: 48px;
  }
}

.kid_timline_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //  max-width: $screen-max-width;
  margin: 0 auto;
  padding-top: 0;
  width: 100%;
  transition: all $transition-speed;
  max-height: 250px;
  box-shadow: 0px 20px 17px -17px rgba(0, 0, 0, 0.5);

  :global(.ant-badge) > img {
    transition: all $transition-speed;
  }
}

.cardContainer {
  cursor: pointer;
  // width: 13.29%;
  @include aspect-ratio(170, 107.77);
  // position: absolute;
  margin-left: $left-margin;
  z-index: 11 !important;
}

.parallax {
  // max-width: 375px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.badge {
  width: 147.6px;
  height: 140.4px;

  margin: auto;
  margin-bottom: 20px;
  background-image: url('../../assets/images/Badge.svg');
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-items: center;
  color: $white;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  transition: all $transition-speed;
}

.smallOnly{
  display: block;
  @media screen and (min-width: $break-point) {
    display: none;
  }
}

.bigOnly{
  display: none;
  @media screen and (min-width: $break-point) {
    display: block;
  }
}

.kid_section {
  display: flex;
  justify-content: space-between;
}

.child_margin {
  // margin: 1rem;
  transition: all $transition-speed;
  width: 144px;
  height: 138px;
}

// Age number (Mobile)
.ageBlock {
  width: $screen-max-width;
  // background-color: orange;
  position: relative;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.ageBlockAge {
  margin: 0;
  position: absolute;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  z-index: 11;
  top: 372px;
}
.ageBlockAge0 {
  width: 114px;
  right: 19px;
}
.ageBlockAge1 {
  width: 72.16px;
  right: 22.84px;
}
.ageBlockAge2 {
  width: 92.31px;
  right: 21.69px;
  z-index: 0 !important;
}
.ageBlockAge3 {
  width: 92.27px;
  right: 19.73px;
  z-index: 0 !important;
}
.ageBlockAge4 {
  width: 98.38px;
  right: 20.62px;
}
.ageBlockAge5 {
  width: 92.27px;
  right: 19.73px;
}
.ageBlockAge6 {
  width: 94.36px;
  right: 22.64px;
}
.ageBlockAge7 {
  width: 93.81px;
  right: 21.19px;
}
.ageBlockAge8 {
  width: 94.32px;
  right: 18.68px;
}
.ageBlockAge9 {
  width: 94.33px;
  right: 19.67px;
}
.ageBlockAge10 {
  width: 175.29px;
  right: 19.71px;
}
.ageBlockAge11 {
  width: 131.85px;
  right: 20.15px;
}
.ageBlockAge12 {
  width: 151.19px;
  right: 19.81px;
}
.ageBlockAge13 {
  width: 151.96px;
  right: 18.04px;
}
.ageBlockAge14 {
  width: 155.33px;
  right: 18.67px;
}
.ageBlockAge15 {
  width: 156.4px;
  right: 17.6px;
}
.ageBlockAge16 {
  width: 153.52px;
  right: 16.48px;
  z-index: 0 !important;
}
.ageBlockAge17 {
  width: 153.83px;
  right: 19.17px;
}
.ageBlockAge18 {
  width: 154.54px;
  right: 16.48px;
}

.AgeTrigger {
  width: 50px;
  height: 2px;
  // background-color: yellow;
  background-color: transparent;
  top: 40px;
  right: 30px;
  padding: 0;
  text-align: center;
  vertical-align: top;
  position: absolute;
  box-sizing: border-box;
  z-index: 8;
}

.TopTrigger {
  width: 50px;
  height: 2px;
  // background-color: purple;
  background-color: transparent;
  top: 40px;
  right: 30px;
  padding: 0;
  text-align: center;
  vertical-align: top;
  position: absolute;
  box-sizing: border-box;
  z-index: 8;
}

.backgroundLayerContainer{
  width: 375px;
  height: 812px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  // overflow: hidden;
}

.ageBlockContainer {
  text-align: center;
  // width: $screen-max-width;
  height: 812px;
  //  border-bottom: 1px solid black;
  border-bottom: none;
}

.ageBlockContent {
  margin: 0 auto;
  // max-width: 60rem;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;
  height: 100%;
}

.ageImage {
  // width: 60px;
  // height: 131px;
  position: relative;
}

.ageImage2Digits {
  // width: 100px;
  // height: auto;
  // right: 10px;
  // height: 105px;
  position: relative;
}

.ageSign {
  color: $blue-bg;
  background-color: white;
  border: 3px solid $blue-bg;
  width: auto;
  font-weight: 600;
  font-size: 1.3125rem;
  line-height: 1.3125rem;
  border-radius: 36px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 19.5px 20px;
  display: inline-block;
  position: relative;
  margin-left: $left-margin;
  z-index: 15;
}

.GiftCard {
  z-index: 2000;
  width: 75px;
  height: 75px;
  margin: 5px 0;
  position: relative;
}

.GiftCardForType {
  z-index: 2000;
  margin: 5px 0;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    border: 1px solid black;
  }
}

.GiftCardBox {
  width: 25px;
  height: 25px;
  border-radius: 0;
  margin: 0;
  float: left;
}

.backgroundImagePlx {
  position: relative;
}

.backgroundImagePlxPlus {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.backgroundImagePlxBehind {
  z-index: 12;
}
.backgroundImagePlxInfront {
  z-index: 10;
}

.backgroundImageContainer_level_2 {
  z-index: 0;
}

.backgroundImage1 {
  position: relative;
  width: 100%;
  height: auto;
}

:global(.plusIcon) {
  width: 50px;
  height: auto;
  cursor: pointer;
  position: relative;
  //z-index: 999999999;
}

@keyframes pulse-shadow {
  from {
    filter: brightness(10%);
  }
  to {
    filter: brightness(80%);
  }
}

// For the plus icons (Mobile)
.plus {
  position: absolute;
  animation: pulse-shadow 2s infinite alternate;
  filter: contrast(100%);
}

.plus0_1 {
  left: 41.6%;
  right: 45.07%;
  top: 34.85%;
  bottom: 58.99%;
}

.plus1_1 {
  left: 74.4%;
  right: 12.27%;
  top: 11.33%;
  bottom: 82.51%;
}

.plus1_2 {
  left: 36.27%;
  right: 50.4%;
  top: 61.21%;
  bottom: 32.64%;
}

.plus2_1 {
  left: 79.2%;
  right: 7.47%;
  top: 28.33%;
  bottom: 65.52%;
}
.plus2_2 {
  left: 36.27%;
  right: 50.4%;
  top: 77.09%;
  bottom: 16.75%;
}

.plus3_1 {
  left: 45.87%;
  right: 40.8%;
  top: 48.77%;
  bottom: 45.07%;
}

.plus4_1 {
  left: 38.13%;
  right: 48.53%;
  top: 45.2%;
  bottom: 48.65%;
}

.plus5_1 {
  left: 72.53%;
  right: 14.13%;
  top: 15.89%;
  bottom: 77.96%;
}

.plus5_2 {
  left: 29.87%;
  right: 56.8%;
  top: 89.66%;
  bottom: 4.19%;
}

.plus6_1 {
  left: 81.6%;
  right: 5.07%;
  top: 15.27%;
  bottom: 78.57%;
}

.plus6_2 {
  left: 40.8%;
  right: 45.87%;
  top: 76.35%;
  bottom: 17.49%;
}

.plus7_1 {
  left: 36.8%;
  right: 49.87%;
  top: 15.27%;
  bottom: 78.57%;
}

.plus7_2 {
  left: 73.33%;
  right: 13.33%;
  top: 87.68%;
  bottom: 6.16%;
}

.plus8_1 {
  left: 49.6%;
  right: 37.07%;
  top: 44.33%;
  bottom: 49.51%;
}

.plus8_2 {
  left: 26.93%;
  right: 59.73%;
  top: 83.13%;
  bottom: 10.71%;
}

.plus9_1 {
  left: 39.73%;
  right: 46.93%;
  top: 46.06%;
  bottom: 47.78%;
}

.plus9_2 {
  left: 67.2%;
  right: 19.47%;
  top: 89.9%;
  bottom: 3.94%;
}

.plus10_1 {
  left: 38.67%;
  right: 48%;
  top: 17.12%;
  bottom: 76.72%;
}

.plus10_2 {
  left: 79.47%;
  right: 7.2%;
  top: 60.47%;
  bottom: 33.37%;
}

.plus11_1 {
  left: 61.33%;
  right: 25.33%;
  top: 19.09%;
  bottom: 74.75%;
}

.plus11_2 {
  left: 38.67%;
  right: 48%;
  top: 56.53%;
  bottom: 37.32%;
}

.plus12_1 {
  left: 77.6%;
  right: 9.07%;
  top: 18.23%;
  bottom: 75.62%;
}

.plus13_1 {
  left: 36.67%;
  right: 50%;
  top: 86.08%;
  bottom: 7.76%;
}

.plus14_1 {
  left: 32.53%;
  right: 54.13%;
  top: 58.99%;
  bottom: 34.85%;
}

.plus14_2 {
  left: 70.4%;
  right: 16.27%;
  top: 80.42%;
  bottom: 13.42%;
}

.plus15_1 {
  left: 33.87%;
  right: 52.8%;
  top: 37.81%;
  bottom: 56.03%;
}

.plus15_2 {
  left: 60.27%;
  right: 26.4%;
  top: 73.65%;
  bottom: 20.2%;
}

.plus16_1 {
  left: 75.2%;
  right: 11.47%;
  top: 35.22%;
  bottom: 58.62%;
}

.plus16_2 {
  left: 36.53%;
  right: 50.13%;
  top: 60.84%;
  bottom: 33%;
}

.plus17_1 {
  left: 18.4%;
  right: 68.27%;
  top: 39.66%;
  bottom: 54.19%;
}

.plus17_2 {
  left: 74.13%;
  right: 12.53%;
  top: 68.84%;
  bottom: 25%;
}

.plus18_1 {
  left: 37.87%;
  top: 39.78%;
}

.plus18_2 {
  left: 80.26%;
  top: 66.38%;
}

// Plus Icons End (Mobile)
// Age 0
.backgroundImageBitBubble {
  width: 92%;
  height: auto;
  position: absolute;
  top: 11.08%;
  left: 4%;
  // max-width: 423.07px;
}

.backgroundImageSmallCloud {
  width: 29.6%;
  height: auto;
  position: absolute;
  top: 38.67%;
  left: 53.07%;
  min-width: 111px;
  max-width: 136px;
}

.backgroundImageFox {
  width: 70.93%;
  height: auto;
  position: absolute;
  top: 69.21%;
  left: 0;
  min-width: 266px;
  max-width: 326px;
}

// Age 1
.backgroundImageFishes {
  width: 111.56%;
  height: auto;
  position: absolute;
  top: 517px;
  left: 28.53%;
  max-width: none;
}

.backgroundImageJellyfishes {
  width: 88.27%;
  height: auto;
  position: absolute;
  left: 16.53%;
  top: 10.34%;
  margin-top: 0;
}

.backgroundImageAge1Bubbles {
  width: 167.71%;
  height: auto;
  position: absolute;
  left: -36%;
  top: 2.22%;
  margin-top: 0;
  max-width: none;
}

// Age 2
.backgroundImageLeafLeft {
  width: 444px;
  max-width: none;
  height: auto;
  position: absolute;
  top: 173px;
  left: -145px;
  animation: rotate 4s ease-in-out infinite;
}

.backgroundImageLeafRight {
  width: 207.88px;
  max-width: 150%;
  height: auto;
  position: absolute;
  top: 497px;
  right: -21.88px;
  animation: rotate 3s ease-in-out infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(7deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.backgroundImageFlipFlops {
  width: 41.87%;
  height: auto;
  position: absolute;
  top: 81.65%;
  left: 54.67%;
}

.backgroundImageBall {
  width: 43.47%;
  height: auto;
  position: absolute;
  top: 0px;
  left: 56.53%;
}

.backgroundImageSunglasses {
  width: 36%;
  height: auto;
  position: absolute;
  top: 53.82%;
  left: 34.4%;
}

.backgroundImageFlower {
  width: 166px;
  height: auto;
  position: absolute;
  top: 72.54%;
  left: -40px;
}

// Age 3

.backgroundImageBirdGreen {
  width: 26.97%;
  height: auto;
  position: absolute;
  top: 51.85%;
  left: 49.6%;
}

.backgroundImageBirdYellow {
  width: 35.69%;
  height: auto;
  position: absolute;
  left: 11.2%;
  top: 68.35%;
}

.backgroundImageFlamingo {
  width: 197.28px;
  height: auto;
  position: absolute;
  top: 527px;
  left: 164px;
}

.backgroundImageElephant {
  width: 330px;
  height: auto;
  position: absolute;
  top: 104px;
  left: 151px;
  animation: rotate 3s ease-in-out infinite;
}

.backgroundImageStar1 {
  width: 43px;
  height: auto;
  position: absolute;
  top: 104px;
  left: 192.95px;
  transform: rotate(25.9deg);
}
.backgroundImageStar2 {
  width: 70.15px;
  height: auto;
  position: absolute;
  top: 312.68px;
  left: 188px;
  transform: rotate(-19.73deg);
}
.backgroundImageStar3 {
  width: 43px;
  height: auto;
  position: absolute;
  left: 112.43px;
  top: 532px;
  transform: rotate(6.19deg);
}
.backgroundImageStar4 {
  width: 28.07%;
  height: auto;
  position: absolute;
  left: -5%;
  top: 81%;
  transform: rotate(-19.73deg);
}

// Age 4
.backgroundImageSun {
  width: 43.44%;
  height: auto;
  position: absolute;
  top: 16.22%;
  left: 41.74%;
}

.backgroundImageRollerCoaster {
  width: 107.73%;
  max-width: none;
  height: auto;
  position: absolute;
  top: 52.96%;
  left: -5.6%;
}

// Age 5
.backgroundImageLolly1,
.backgroundImageLolly2,
.backgroundImageLolly3 {
  width: 55%;
  height: auto;
  position: absolute;
  left: 25%;
}

.backgroundImageLolly1 {
  top: 129px;
}

.backgroundImageLolly2 {
  top: 333px;
}

.backgroundImageLolly3 {
  top: 537px;
}

.backgroundImageAge5Bubble1 {
  width: 33.01%;
  max-width: none;
  height: auto;
  position: absolute;
  left: -7.96%;
  top: 24.27%;
}
.backgroundImageAge5Bubble2 {
  width: 46.63%;
  max-width: none;
  height: auto;
  position: absolute;
  left: -12.77%;
  top: 69.1%;
}
.backgroundImageAge5Bubble3 {
  width: 67.79%;
  max-width: none;
  height: auto;
  position: absolute;
  left: 54.1%;
  top: 35.7%;
}

// Age 6
.backgroundImageNeonBackground {
  width: 850px;
  max-width: none;
  height: auto;
  position: absolute;
  top: 22px;
  left: -325px;
}

.backgroundImageCassette {
  width: 45.48%;
  height: auto;
  position: absolute;
  left: 66.13%;
  top: 22.78%;
}

.backgroundImageWatch {
  width: 16.86%;
  height: auto;
  position: absolute;
  top: 43.47%;
  left: 34.67%;
}

.backgroundImageBoomBox {
  width: 138.57px;
  height: auto;
  position: absolute;
  left: 39px;
  top: 703px;
}

.backgroundImageGreenRobot {
  width: 85.6%;
  height: auto;
  position: absolute;
  top: 4.56%;
  left: 10.4%;
}

.backgroundImagePinkRobot {
  width: 80%;
  height: auto;
  position: absolute;
  top: 564px;
  left: 106px;
}

// Age 7
.backgroundImageAge7Twinkle1,
.backgroundImageAge7Twinkle2,
.backgroundImageAge7Twinkle3,
.backgroundImageAge7Twinkle4 {
  width: 24.53%;
  position: absolute;
  height: auto;
}

.backgroundImageAge7Twinkle1 {
  top: 34.36%;
  left: 75.2%;
}
.backgroundImageAge7Twinkle2 {
  top: 59.98%;
  left: 0;
}
.backgroundImageAge7Twinkle3 {
  top: 72.41%;
  left: 75.47%;
}
.backgroundImageAge7Twinkle4 {
  top: 88.92%;
  left: 0;
}
.backgroundImageRocket {
  width: 40%;
  height: auto;
  position: absolute;
  top: 2.09%;
  left: 59.47%;
  line-height: 0;
}
.backgroundImagePurplePlanet {
  width: 23.73%;
  height: auto;
  position: absolute;
  top: 18.35%;
  left: 51.47%;
  animation: pulse 2s ease-in-out infinite;
}

.backgroundImageYellowPlanet {
  width: 16.8%;
  height: auto;
  position: absolute;
  top: 26.85%;
  left: 80.25%;
  animation: pulse 1.5s ease-in-out infinite;
}

.backgroundImageRingPlanet {
  width: 36.8%;
  height: auto;
  position: absolute;
  top: 32.76%;
  left: 38.93%;
  animation: pulse 3s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

.backgroundImageAstronaut {
  width: 54.93%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 20%;
}

// Age 8
.backgroundImageNeonCoffee,
.backgroundImageNeonCroissant,
.backgroundImageNeonDonut,
.backgroundImageNeonNoodles {
  height: auto;
  position: absolute;
}

.backgroundImageNeonCoffee {
  width: 52.53%;
  top: 7.27%;
  left: 51.2%;
}
.backgroundImageNeonCroissant {
  width: 38.9%;
  top: 23.45%;
  left: 34.78%;
  transform: rotate(-114.09deg);
}
.backgroundImageNeonDonut {
  width: 52%;
  top: 54.43%;
  left: 8%;
}
.backgroundImageNeonNoodles {
  width: 72%;
  top: 66.75%;
  left: 34.4%;
}

// Age 9
.backgroundImageSkateShadow {
  width: 46.67%;
  height: auto;
  position: absolute;
  top: 13.6%;
  left: 39.73%;
  transform: translateZ(0);
}

.backgroundImageSkateboard {
  width: 91.73%;
  height: auto;
  position: absolute;
  top: 51.72%;
  left: 4%;
}

// Age 10

.backgroundImageAge10Twinkle1,
.backgroundImageAge10Twinkle2,
.backgroundImageAge10Twinkle3,
.backgroundImageAge10Twinkle4 {
  width: 24.53%;
  position: absolute;
  height: auto;
}

.backgroundImageAge10Twinkle1 {
  top: 21.43%;
  left: 62.67%;
}
.backgroundImageAge10Twinkle2 {
  top: 46.67%;
  left: 27.47%;
}
.backgroundImageAge10Twinkle3 {
  top: 77.71%;
  left: 0;
}
.backgroundImageAge10Twinkle4 {
  top: 92%;
  left: 75.47%;
}

.backgroundImageStamp {
  width: 80.02%;
  height: auto;
  position: absolute;
  top: 4.68%;
  left: 33.6%;
  max-width: none;
}

.backgroundImageSuitcase {
  width: 100.53%;
  max-width: 200%;
  height: 46.43;
  position: absolute;
  top: 56.16%;
  left: 0px;
  max-width: none;
}

// Age 11
.backgroundImageSkyCity {
  width: 181.07%;
  height: auto;
  position: absolute;
  max-width: none;
  top: 13.3%;
  left: -34.93%;
}

// Age 12
.backgroundImageBlueAirBalloon {
  width: 68.98%;
  height: auto;
  position: absolute;
  max-width: none;
  top: 37.68%;
  left: -17.33%;
  animation: move 2s ease-in-out infinite;
}
.backgroundImagePinkAirBalloon {
  width: 18.13%;
  height: auto;
  position: absolute;
  max-width: none;
  top: 11.95%;
  left: 56%;
  animation: move 2.5s ease-in-out infinite;
}
.backgroundImageGreenAirBalloon {
  width: 35.5%;
  height: auto;
  position: absolute;
  max-width: none;
  top: 71.8%;
  left: 68%;
  animation: move 3s ease-in-out infinite;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.backgroundImageAirBaloonShadow1,
.backgroundImageAirBaloonShadow3{
  animation: move 2.5s ease-in-out infinite;
}
.backgroundImageAirBaloonShadow2,
.backgroundImageAirBaloonShadow4{
  animation: move 4s ease-in-out infinite;
}

.backgroundImageAirBaloonShadow1 {
  width: 6.14%;
  height: auto;
  position: absolute;
  max-width: none;
  top: 22.29%;
  left: 51.73%;
}
.backgroundImageAirBaloonShadow2 {
  width: 10.13%;
  height: auto;
  position: absolute;
  max-width: none;
  top: 35.22%;
  left: 75.73%;
}
.backgroundImageAirBaloonShadow3 {
  width: 10.13%;
  height: auto;
  position: absolute;
  max-width: none;
  top: 73.89%;
  left: 36%;
}
.backgroundImageAirBaloonShadow4 {
  width: 6.14%;
  height: auto;
  position: absolute;
  max-width: none;
  top: 91.13%;
  left: 6.13%;
}

.backgroundImageAge12Cloud1,
.backgroundImageAge12Cloud2,
.backgroundImageAge12Cloud3 {
  width: 37.6%;
  height: auto;
  position: absolute;
  max-width: none;
}

.backgroundImageAge12Cloud1 {
  top: 26.48%;
  left: 67.73%;
}
.backgroundImageAge12Cloud2 {
  top: 58%;
  left: -6.67%;
}
.backgroundImageAge12Cloud3 {
  top: 88.79%;
  left: 31.47%;
}

// Age 13
.backgroundImageGreenCamera {
  width: 53.6%;
  max-width: none;
  height: auto;
  position: absolute;
  top: 19.38%;
  left: 48.61%;
  transform: rotate(10.31deg);
}
.backgroundImageYellowCamera {
  width: 53.6%;
  max-width: none;
  height: auto;
  position: absolute;
  top: 61.45%;
  left: 47.2%;
  transform: rotate(-7.37deg);
}

.backgroundImageFilmRoll11,
.backgroundImageFilmRoll12 {
  width: 18.3%;
  max-width: none;
  height: auto;
  position: absolute;
}

.backgroundImageFilmRoll11 {
  top: 17.0%;
  left: 42.49%;
  transform: rotate(-15.33deg);
}
.backgroundImageFilmRoll12 {
  top: 87.93%;
  left: 70.13%;
  transform: matrix(-0.96, -0.26, -0.26, 0.96, 0, 0);
}
.backgroundImageFilm {
  width: 29.52%;
  max-width: none;
  height: auto;
  position: absolute;
  top: 64.16%;
  left: 80.53%;
}

.backgroundImageAge13Twinkle1 {
  width: 20.8%;
  max-width: none;
  height: auto;
  position: absolute;
  top: 10.02%;
  left: 55.19%;
  transform: rotate(-8.53deg);
}
.backgroundImageAge13Twinkle2 {
  width: 19.85%;
  max-width: none;
  height: auto;
  position: absolute;
  top: 44.04%;
  left: 38.57%;
  transform: rotate(19.99deg);
}
.backgroundImageAge13Twinkle3 {
  width: 24.53%;
  max-width: none;
  height: auto;
  position: absolute;
  top: 59.98%;
  left: 0;
}
.backgroundImageAge13Twinkle4 {
  width: 20.8%;
  max-width: none;
  height: auto;
  position: absolute;
  top: 80.83%;
  left: 19.46%;
  transform: rotate(-8.53deg);
}
.backgroundImageAge13Twinkle5 {
  width: 18.83%;
  max-width: none;
  height: auto;
  position: absolute;
  top: 93.43%;
  left: 83.82%;
  transform: rotate(27.78deg);
}

// Age 14
.backgroundImageGaming {
  width: 130.99%;
  max-width: none;
  height: auto;
  position: relative;
  top: 15.02%;
  left: 2.13%;
  margin: auto;
}

.backgroundImageCoinFlip1,
.backgroundImageCoinFlip2,
.backgroundImageCoinFlip3,
.backgroundImageCoinFlip4 {
  width: 12.27%;
  max-width: none;
  height: auto;
  position: absolute;
}

.backgroundImageCoinFlip1 {
  top: 5.42%;
  left: 77.07%;
}
.backgroundImageCoinFlip2 {
  top: 38.42%;
  left: 58.4%;
}
.backgroundImageCoinFlip3 {
  top: 67.98%;
  left: 36.8%;
}
.backgroundImageCoinFlip4 {
  top: 93.72%;
  left: 57.07%;
}

// Age 15
.backgroundImageTickets {
  width: 89.07%;
  height: auto;
  position: absolute;
  top: 9.36%;
  left: 18.93%;
}

.backgroundImageHeadphones {
  width: 63.67%;
  height: auto;
  position: absolute;
  top: 66.52%;
  left: 33.65%;
  transform: rotate(14.56deg);
}
.backgroundImageRecordPlayer {
  width: 59.15%;
  height: auto;
  position: absolute;
  top: 46.92%;
  left: -4%;
}

// Age 16
.backgroundImageCloudDreams {
  width: 110.67%;
  max-width: none;
  height: auto;
  position: absolute;
  top: 6.65%;
  left: 9.87%;
  z-index: 12;
}

.backgroundImageSprayPaint {
  width: 36.79%;
  height: auto;
  position: absolute;
  top: 73.28%;
  left: 49.87%;
}

// Age 17
.backgroundImageLighteningBolt1 {
  width: 24.68%;
  height: auto;
  position: absolute;
  top: 17.12%;
  left: 60.65%;
}

.backgroundImageLighteningBolt2 {
  width: 17.12%;
  height: auto;
  position: absolute;
  top: 64.42%;
  left: 36.12%;
}

.backgroundImageBattery1,
.backgroundImageBattery2,
.backgroundImageBattery3 {
  width: 38.93%;
  height: auto;
  position: absolute;
  top: 80.67%;
}

.backgroundImageBattery1 {
  left: 0;
}
.backgroundImageBattery2 {
  left: 30.93%;
}
.backgroundImageBattery3 {
  left: 61.87%;
}

// Age 18
.backgroundImageGraduationCap {
  width: 48%;
  max-width: none;
  height: auto;
  position: absolute;
  top: 14.9%;
  left: 43.47%;
}
.backgroundImageCoinFountain {
  width: 94.13%;
  max-width: none;
  height: auto;
  position: absolute;
  top: 45.2%;
  left: -15.73%;
}

// End

.backgroundText {
  width: 180px;
  height: auto;
  position: absolute;
  top: 120px;
  left: 100px;
  margin: auto;
  z-index: 9;
  text-align: left;
  font-size: 16px;
  p {
    font-size: 18px;
  }
  h1 {
    font-family: 'ProximaNova Bold';
    font-size: 20px;
    color: $text-dark;
    line-height: 1.4em;
    letter-spacing: -0.408px;
    margin-top: 0px;
    margin-bottom: 1.2em;
  }
}

.backgroundImageLighteningBoltLarge {
  width: 150px;
  height: auto;
  position: absolute;
  top: 300px;
  left: 50%;
  margin: auto;
  margin-left: -120px;
}

.bottomFrame {
  height: 300px;
  background-image: url('../../assets/images/timeline/seaBed.png');
  background-repeat: no-repeat;
  background-size: $screen-max-width auto;
  position: fixed;
  background-position: bottom;
  bottom: 0;
  right: 0;
  z-index: 60000;
}

.modalBackground {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.giftCardImageContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 40px;
  width: 100%;
  .giftCardImage {
    width: 275px;
    height: 275px;
  }
  .giftCardImageValueContainer {
    position: absolute;
    top: 0;
    width: 322px;
    height: 275px;
    font-family: 'intro-inline';
    .giftCardImageValue {
      font-size: 50px;
      color: white;
      max-width: 92px;
      overflow: hidden;
      display: block;
      width: 92px;
      text-align: center;
      height: 55px;
      div {
        height: 100%;
        display: flex !important;
        width: 100%;
        justify-content: center;
        div {
          display: block !important;
          width: 100%;
        }
      }
    }
    .giftCardImageCurrency {
      font-size: 18px;
      color: white;
      display: block;
      max-width: 92px;
      overflow: hidden;
      display: block;
      width: 92px;
      text-align: center;
    }
  }
}

.giftMessageContainer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 0 40px;
  color: white;
  text-align: center;
  font-weight: bold;
}

$giftbox-bottom: 30px;

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: $giftbox-bottom;
  }
  50% {
    bottom: $giftbox-bottom + 10px;
  }
}

.giftBoxContainer {
  // position: absolute;
  // bottom: $giftbox-bottom;
  // width: 100%;
  // display: flex;
  // justify-content: center;
  animation: MoveUpDown 1s linear infinite;
  // a {
  //   width: 80px;
  //   cursor: pointer;
  //   height: 100%;
  //   img {
  //     width: 100%;
  //   }
  // }
}
.openMeContainer {
  // position: absolute;
  bottom: $giftbox-bottom;
  width: 100%;
  display: flex;
  justify-content: center;
  .openMe {
    width: 75px;
    display: flex;
    justify-content: center;
    margin-left: 180px;
  }
}

.giftCardCloseButton {
  position: absolute;
  right: 10px;
  top: 10px;
}

.giftCardMosaicMarker {
  position: absolute;
  left: 10px;
  bottom: 210px;
  height: 32px;
  width: 32px;
}

@media only screen and (min-width: $break-point) {

  .backgroundLayerContainer{
    position: static;
    width: 100%;
    height: 100%;
    transform: none;
  }

  .child_margin {
    width: 176.35px;
    height: 180px;
  }

  .cardContainer {
    cursor: pointer;
    // width: 13.29%;
    @include aspect-ratio(170, 107.77);
    // position: absolute;
    min-width: 150px;
    margin-left: 0;
    z-index: 11 !important;
  }

  .ageSign {
    margin-left: 0;
  }

  .ageBlockContainer {
    width: 100%;
    height: auto;
    min-height: 800px;
  }
  .ageBlockContainer0 {
    @include aspect-ratio(1280, 862);
    min-height: 860px !important;
  }
  .ageBlockContainer1 {
    @include aspect-ratio(1280, 1150);
  }
  .ageBlockContainer2 {
    @include aspect-ratio(1280, 1117);
  }
  .ageBlockContainer3 {
    @include aspect-ratio(1280, 1087);
  }
  .ageBlockContainer4 {
    @include aspect-ratio(1280, 1013);
  }
  .ageBlockContainer5 {
    @include aspect-ratio(1280, 920);
  }
  .ageBlockContainer6 {
    @include aspect-ratio(1280, 986);
  }
  .ageBlockContainer7 {
    @include aspect-ratio(1280, 862);
  }
  .ageBlockContainer8 {
    @include aspect-ratio(1280, 862);
  }
  .ageBlockContainer9 {
    @include aspect-ratio(1280, 912);
  }
  .ageBlockContainer10 {
    @include aspect-ratio(1280, 1600);
  }
  .ageBlockContainer11 {
    @include aspect-ratio(1280, 1148);
  }
  .ageBlockContainer12 {
    @include aspect-ratio(1280, 1475);
  }
  .ageBlockContainer13 {
    @include aspect-ratio(1280, 946);
  }
  .ageBlockContainer14 {
    @include aspect-ratio(1280, 1409);
  }
  .ageBlockContainer15 {
    @include aspect-ratio(1280, 911);
  }
  .ageBlockContainer16 {
    @include aspect-ratio(1280, 1545);
  }
  .ageBlockContainer17 {
    @include aspect-ratio(1280, 1274);
  }
  .ageBlockContainer18 {
    @include aspect-ratio(1280, 1185);
  }

  // Background Images (Desktop)

  // Age 0
  .backgroundImagePlx {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .backgroundImageBitBubble {
    position: absolute;
    width: 33.05%;
    top: 10.44%;
    left: 13.2%;
    // min-width: 423.07px;
    height: auto;
  }

  .backgroundImageSmallCloud {
    width: 8.45%;
    height: auto;
    position: absolute;
    top: 38.68%;
    left: 77.58%;
    min-width: 108.17px;
  }

  .backgroundImageFox {
    height: auto;
    position: absolute;
    width: 25.47%;
    top: 65.2%;
    left: 45.08%;
    min-width: 266px;
  }

  // Age 1
  .backgroundImageFishes {
    width: 43.2%;
    height: auto;
    position: absolute;
    top: 61.39%;
    left: 46.69%;
  }

  .backgroundImageJellyfishes {
    width: 30.7%;
    // min-width: 294px;
    height: auto;
    position: absolute;
    left: 20.16%;
    top: 11.65%;
    margin-top: 0;
  }

  .backgroundImageAge1Bubbles {
    width: 64.93%;
    height: auto;
    position: absolute;
    left: 21.72%;
    top: 4.09%;
    max-width: none;
  }

  // Age 2
  .backgroundImageLeafLeft {
    width: 45.71%;
    max-width: none;
    height: auto;
    position: absolute;
    top: 20.41%;
    left: -11.33%;
  }

  .backgroundImageLeafRight {
    width: 21.4%;
    max-width: 150%;
    height: auto;
    position: absolute;
    top: 58.63%;
    left: 62.5%;
  }

  .backgroundImageFlipFlops {
    width: 16.17%;
    height: auto;
    position: absolute;
    top: 78.25%;
    left: 78.12%;
  }

  .backgroundImageBall {
    width: 16.8%;
    height: auto;
    position: absolute;
    top: 0;
    left: 78.12%;
  }

  .backgroundImageSunglasses {
    width: 13.91%;
    height: auto;
    position: absolute;
    top: 51.57%;
    left: 42.97%;
  }

  .backgroundImageFlower {
    width: 17.09%;
    height: auto;
    position: absolute;
    top: 69.48%;
    left: 7.81%;
  }

  // Age 3
  .backgroundImageBirdGreen {
    width: 10.34%;
    height: auto;
    position: absolute;
    top: 49.21%;
    left: 56.32%;
  }

  .backgroundImageBirdYellow {
    width: 13.69%;
    height: auto;
    position: absolute;
    left: 25.97%;
    top: 65.34%;
  }

  .backgroundImageFlamingo {
    width: 20.17%;
    height: auto;
    position: absolute;
    top: 61.97%;
    left: 70.31%;
  }

  .backgroundImageElephant {
    width: 33.74%;
    height: auto;
    position: absolute;
    top: 11.04%;
    left: 52.74%;
  }

  .backgroundImageStar1 {
    width: 4.4%;
    height: auto;
    position: absolute;
    top: 11.04%;
    left: 57.03%;
    transform: rotate(25.9deg);
  }
  .backgroundImageStar2 {
    width: 7.17%;
    height: auto;
    position: absolute;
    top: 36.16%;
    left: 56.53%;
    transform: rotate(-19.73deg);
  }
  .backgroundImageStar3 {
    width: 4.4%;
    height: auto;
    position: absolute;
    left: 33.17%;
    top: 62.57%;
    transform: rotate(6.19deg);
  }
  .backgroundImageStar4 {
    width: 10.76%;
    height: auto;
    position: absolute;
    left: 18.2%;
    top: 79.73%;
    transform: rotate(-19.73deg);
  }

  // Age 4
  .backgroundImageSun {
    width: 18.76%;
    height: auto;
    position: absolute;
    top: 15.25%;
    left: 56.47%;
  }

  .backgroundImageRollerCoaster {
    width: 46.88%;
    max-width: none;
    height: auto;
    position: absolute;
    top: 42.45%;
    left: 42.97%;
  }

  // Age 5
  .backgroundImageLolly1,
  .backgroundImageLolly2,
  .backgroundImageLolly3 {
    width: 17.73%;
    height: auto;
    position: relative;
    left: 42.97%;
  }

  .backgroundImageLolly1 {
    top: 14.02%;
  }

  .backgroundImageLolly2 {
    top: 38.7%;
  }

  .backgroundImageLolly3 {
    top: 63.37%;
  }
  .backgroundImageAge5Bubble1 {
    width: 12.93%;
    max-width: none;
    height: auto;
    position: absolute;
    left: 16.89%;
    top: 14.04%;
  }
  .backgroundImageAge5Bubble2 {
    width: 18.26%;
    max-width: none;
    height: auto;
    position: absolute;
    left: 33.66%;
    top: 66.92%;
  }
  .backgroundImageAge5Bubble3 {
    width: 26.54%;
    max-width: none;
    height: auto;
    position: absolute;
    left: 76.81%;
    top: 27.52%;
  }

  // Age 6
  .backgroundImageNeonBackground {
    width: 70.23%;
    max-width: none;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 15.7%;
    left: auto;
  }

  .backgroundImageCassette {
    width: 16%;
    height: auto;
    position: absolute;
    left: 71.66%;
    top: 20.51%;
  }

  .backgroundImageWatch {
    width: 4.86%;
    height: auto;
    position: absolute;
    top: 40.97%;
    left: 31.25%;
  }

  .backgroundImageBoomBox {
    width: 13%;
    height: auto;
    position: absolute;
    left: 20.8%;
    top: 83.6%;
  }

  .backgroundImagePinkRobot {
    width: 29.06%;
    height: auto;
    position: absolute;
    top: 66.63%;
    left: 27.11%;
  }

  .backgroundImageGreenRobot {
    width: 30.16%;
    height: auto;
    position: absolute;
    top: 2.54%;
    left: 39.06%;
  }

  // Age 7
  .backgroundImageAge7Twinkle1,
  .backgroundImageAge7Twinkle2,
  .backgroundImageAge7Twinkle3,
  .backgroundImageAge7Twinkle4 {
    width: 7.19%;
    position: absolute;
    height: auto;
  }

  .backgroundImageAge7Twinkle1 {
    top: 32.37%;
    left: 53.28%;
  }
  .backgroundImageAge7Twinkle2 {
    top: 56.5%;
    left: 23.2%;
  }
  .backgroundImageAge7Twinkle3 {
    top: 68.21%;
    left: 45.31%;
  }
  .backgroundImageAge7Twinkle4 {
    top: 83.76%;
    left: 23.2%;
  }
  .backgroundImageRocket {
    width: 11.65%;
    height: auto;
    position: absolute;
    top: 1.97%;
    left: 62.5%;
  }
  .backgroundImagePurplePlanet {
    width: 6.94%;
    height: auto;
    position: absolute;
    top: 17.29%;
    left: 54.69%;
  }

  .backgroundImageYellowPlanet {
    width: 4.9%;
    height: auto;
    position: absolute;
    top: 25.29%;
    left: 69.38%;
  }

  .backgroundImageRingPlanet {
    width: 11.08%;
    height: auto;
    position: absolute;
    top: 30.86%;
    left: 42.66%;
  }

  .backgroundImageAstronaut {
    width: 16.09%;
    height: auto;
    position: absolute;
    top: 47.1%;
    left: 29.06%;
  }

  // Age 8
  .backgroundImageNeonCoffee,
  .backgroundImageNeonCroissant,
  .backgroundImageNeonDonut,
  .backgroundImageNeonNoodles {
    height: auto;
    position: absolute;
  }

  .backgroundImageNeonCoffee {
    width: 18.67%;
    top: 0;
    left: 70.1%;
  }
  .backgroundImageNeonCroissant {
    width: 13.81%;
    top: 17.28%;
    left: 64.33%;
    transform: rotate(-114.09deg);
  }
  .backgroundImageNeonDonut {
    width: 18.44%;
    top: 42.23%;
    left: 33.59%;
  }
  .backgroundImageNeonNoodles {
    width: 25.55%;
    top: 56.26%;
    left: 42.97%;
  }

  // Age 9
  .backgroundImageSkateShadow {
    width: 17.73%;
    height: auto;
    position: absolute;
    top: 12.39%;
    margin: 0;
    left: 27.34%;
  }

  .backgroundImageSkateboard {
    width: 32.81%;
    height: auto;
    position: absolute;
    top: 56.25%;
    margin: 0;
    left: 55.55%;
  }

  // Age 10
  .backgroundImageAge10Twinkle1,
  .backgroundImageAge10Twinkle2,
  .backgroundImageAge10Twinkle3,
  .backgroundImageAge10Twinkle4 {
    width: 7.19%;
    position: absolute;
    height: auto;
  }

  .backgroundImageAge10Twinkle1 {
    top: 14.31%;
    left: 63.2%;
  }
  .backgroundImageAge10Twinkle2 {
    top: 45.12%;
    left: 32.34%;
  }
  .backgroundImageAge10Twinkle3 {
    top: 77.44%;
    left: 7.81%;
  }
  .backgroundImageAge10Twinkle4 {
    top: 98.75%;
    left: 75.62%;
  }

  .backgroundImageStamp {
    width: 44.56%;
    height: auto;
    position: absolute;
    top: 8.99%;
    left: 60.55%;
    max-width: none;
  }

  .backgroundImageSuitcase {
    width: 100%;
    max-width: 200%;
    height: 46.43;
    position: absolute;
    top: 37.56%;
    left: 0;
    max-width: none;
  }

  // Age 11
  .backgroundImageSkyCity {
    width: 78.12%;
    height: auto;
    position: absolute;
    max-width: none;
    top: 9.41%;
    left: 10.94%;
  }

  // Age 12
  .backgroundImageBlueAirBalloon {
    width: 39.05%;
    height: auto;
    position: absolute;
    max-width: none;
    top: 33.95%;
    left: -3.36%;
  }
  .backgroundImagePinkAirBalloon {
    width: 10.26%;
    height: auto;
    position: absolute;
    max-width: none;
    top: 6.58%;
    left: 48.86%;
  }
  .backgroundImageGreenAirBalloon {
    width: 20.1%;
    height: auto;
    position: absolute;
    max-width: none;
    top: 70.24%;
    left: 82.06%;
  }

  .backgroundImageAirBaloonShadow1 {
    width: 3.47%;
    height: auto;
    position: absolute;
    max-width: none;
    top: 17.58%;
    left: 46.44%;
  }
  .backgroundImageAirBaloonShadow2 {
    width: 5.74%;
    height: auto;
    position: absolute;
    max-width: none;
    top: 31.33%;
    left: 60.03%;
  }
  .backgroundImageAirBaloonShadow3 {
    width: 5.74%;
    height: auto;
    position: absolute;
    max-width: none;
    top: 72.47%;
    left: 33.24%;
  }
  .backgroundImageAirBaloonShadow4 {
    width: 3.47%;
    height: auto;
    position: absolute;
    max-width: none;
    top: 90.81%;
    left: 9.92%;
  }

  .backgroundImageAge12Cloud2,
  .backgroundImageAge12Cloud3 {
    width: 21.25%;
    height: auto;
    position: absolute;
    max-width: none;
  }

  .backgroundImageAge12Cloud1 {
    width: 21.33%;
    top: 22.03%;
    left: 55.47%;
  }
  .backgroundImageAge12Cloud2 {
    top: 55.59%;
    left: 2.66%;
  }
  .backgroundImageAge12Cloud3 {
    top: 88.34%;
    left: 57.73%;
  }

  // Age 13
  .backgroundImageGreenCamera {
    width: 19.8%;
    max-width: none;
    height: auto;
    position: absolute;
    top: 10.32%;
    left: 66.72%;
    transform: rotate(10.31deg);
  }
  .backgroundImageYellowCamera {
    width: 19.81%;
    max-width: none;
    height: auto;
    position: absolute;
    top: 55.84%;
    left: 36.65%;
    transform: rotate(-7.37deg);
  }

  .backgroundImageFilmRoll11,
  .backgroundImageFilmRoll12 {
    width: 6.76%;
    max-width: none;
    height: auto;
    position: absolute;
  }

  .backgroundImageFilmRoll11 {
    top: 7.75%;
    left: 64.46%;
    transform: rotate(-15.33deg);
  }
  .backgroundImageFilmRoll12 {
    top: 84.57%;
    left: 44.92%;
    transform: matrix(-0.96, -0.26, -0.26, 0.96, 0, 0);
  }
  .backgroundImageFilm {
    width: 10.91%;
    max-width: none;
    height: auto;
    position: absolute;
    top: 58.77%;
    left: 48.97%;
  }

  .backgroundImageAge13Twinkle1 {
    width: 7.69%;
    max-width: none;
    height: auto;
    position: absolute;
    top: 0.19%;
    left: 69.15%;
    transform: rotate(-8.53deg);
  }
  .backgroundImageAge13Twinkle2 {
    width: 7.33%;
    max-width: none;
    height: auto;
    position: absolute;
    top: 37.01%;
    left: 63.01%;
    transform: rotate(19.99deg);
  }
  .backgroundImageAge13Twinkle3 {
    width: 9.06%;
    max-width: none;
    height: auto;
    position: absolute;
    top: 54.12%;
    left: 19.22%;
  }
  .backgroundImageAge13Twinkle4 {
    width: 7.69%;
    max-width: none;
    height: auto;
    position: absolute;
    top: 76.84%;
    left: 26.41%;
    transform: rotate(-8.53deg);
  }
  .backgroundImageAge13Twinkle5 {
    width: 6.96%;
    max-width: none;
    height: auto;
    position: absolute;
    top: 90.47%;
    left: 50.18%;
    transform: rotate(27.78deg);
  }

  // Age 14
  .backgroundImageGaming {
    width: 77.72%;
    max-width: none;
    height: auto;
    position: absolute;
    top: 12.49%;
    left: 15.86%;
    margin: auto;
  }

  .backgroundImageCoinFlip1,
  .backgroundImageCoinFlip3,
  .backgroundImageCoinFlip4 {
    width: 5.78%;
    max-width: none;
    height: auto;
    position: absolute;
  }

  .backgroundImageCoinFlip1 {
    top: 3.55%;
    left: 67.03%;
  }
  .backgroundImageCoinFlip2 {
    width: 5.86%;
    top: 34.28%;
    left: 46.88%;
  }
  .backgroundImageCoinFlip3 {
    top: 61.75%;
    left: 47.97%;
  }
  .backgroundImageCoinFlip4 {
    top: 85.81%;
    left: 57.58%;
  }

  // Age 15
  .backgroundImageTickets {
    width: 35.08%;
    height: auto;
    position: absolute;
    top: -5.27%;
    left: 62.5%;
  }

  .backgroundImageHeadphones {
    width: 25.05%;
    height: auto;
    position: absolute;
    top: 62.93%;
    left: 51.14%;
    transform: rotate(14.56deg);
  }
  .backgroundImageRecordPlayer {
    width: 23.27%;
    height: auto;
    position: absolute;
    top: 39.69%;
    left: 23.28%;
  }

  // Age 16
  .backgroundImageCloudDreams {
    width: 61.56%;
    max-width: none;
    height: auto;
    position: absolute;
    top: 3.5%;
    left: 46.88%;
    z-index: 12;
  }

  .backgroundImageSprayPaint {
    width: 20.61%;
    height: auto;
    position: absolute;
    top: 69.98%;
    left: 69.13%;
  }

  // Age 17
  .backgroundImageLighteningBolt1 {
    width: 13.58%;
    height: auto;
    position: absolute;
    top: 10.91%;
    left: 56.81%;
  }

  .backgroundImageLighteningBolt2 {
    width: 9.42%;
    height: auto;
    position: absolute;
    top: 54.95%;
    left: 43.31%;
  }

  .backgroundImageBattery1,
  .backgroundImageBattery2,
  .backgroundImageBattery3 {
    width: 21.42%;
    height: auto;
    position: absolute;
    top: 78.49%;
  }

  .backgroundImageBattery1 {
    left: 23.44%;
  }
  .backgroundImageBattery2 {
    left: 40.46%;
  }
  .backgroundImageBattery3 {
    left: 57.48%;
  }

  // Age 18
  .backgroundImageGraduationCap {
    width: 21.64%;
    max-width: none;
    height: auto;
    position: absolute;
    top: 10.21%;
    left: 62.5%;
  }
  .backgroundImageCoinFountain {
    width: 42.5%;
    max-width: none;
    height: auto;
    position: absolute;
    top: 42.19%;
    left: 26.64%;
  }
  // Background Images End (Desktop)

  // Plus Icons (Desktop)
  .plus0_1 {
    left: 61.25%;
    right: 34.06%;
    top: 33.87%;
    bottom: 59.16%;
  }

  .plus1_1 {
    left: 82.58%;
    right: 13.52%;
    top: 7.83%;
    bottom: 87.83%;
  }

  .plus1_2 {
    left: 39.06%;
    right: 57.03%;
    top: 51.57%;
    bottom: 44.09%;
  }

  .plus2_1 {
    left: 62.5%;
    right: 33.59%;
    top: 24.08%;
    bottom: 71.44%;
  }
  .plus2_2 {
    left: 35.16%;
    right: 60.88%;
    top: 69.38%;
    bottom: 26.14%;
  }

  .plus3_1 {
    left: 53.2%;
    right: 42.89%;
    top: 52.99%;
    bottom: 42.41%;
  }

  .plus4_1 {
    left: 31.25%;
    right: 64.84%;
    top: 36.23%;
    bottom: 58.84%;
  }

  .plus5_1 {
    left: 83.75%;
    right: 12.34%;
    top: 14.01%;
    bottom: 80.56%;
  }

  .plus5_2 {
    left: 27.34%;
    right: 68.75%;
    top: 79.04%;
    bottom: 15.53%;
  }

  .plus6_1 {
    left: 83.75%;
    right: 12.34%;
    top: 10.65%;
    bottom: 84.28%;
  }

  .plus6_2 {
    left: 31.25%;
    right: 64.84%;
    top: 68.56%;
    bottom: 26.37%;
  }

  .plus7_1 {
    left: 30.62%;
    right: 65.47%;
    top: 14.39%;
    bottom: 79.81%;
  }

  .plus7_2 {
    left: 70.31%;
    right: 25.78%;
    top: 82.6%;
    bottom: 11.6%;
  }

  .plus8_1 {
    left: 61.41%;
    right: 34.69%;
    top: 41.76%;
    bottom: 52.44%;
  }

  .plus8_2 {
    left: 27.34%;
    right: 68.75%;
    top: 78.31%;
    bottom: 15.89%;
  }

  .plus9_1 {
    left: 61.41%;
    right: 34.69%;
    top: 39.47%;
    bottom: 55.04%;
  }

  .plus9_2 {
    left: 27.34%;
    right: 68.75%;
    top: 74.01%;
    bottom: 20.5%;
  }

  .plus10_1 {
    left: 49.77%;
    right: 46.33%;
    top: 25.67%;
    bottom: 71.21%;
  }

  .plus10_2 {
    left: 80.39%;
    right: 15.7%;
    top: 50.47%;
    bottom: 46.41%;
  }

  .plus11_1 {
    left: 78.12%;
    right: 17.97%;
    top: 13.5%;
    bottom: 82.14%;
  }

  .plus11_2 {
    left: 31.25%;
    right: 64.84%;
    top: 48.61%;
    bottom: 47.04%;
  }

  .plus12_1 {
    left: 62.5%;
    right: 33.59%;
    top: 10.03%;
    bottom: 86.58%;
  }

  .plus13_1 {
    left: 70.31%;
    right: 25.78%;
    top: 75.48%;
    bottom: 19.24%;
  }

  .plus14_1 {
    left: 39.06%;
    right: 57.03%;
    top: 54.29%;
    bottom: 42.16%;
  }

  .plus14_2 {
    left: 85.94%;
    right: 10.16%;
    top: 66.64%;
    bottom: 29.81%;
  }

  .plus15_1 {
    left: 42.97%;
    right: 53.12%;
    top: 33.7%;
    bottom: 60.81%;
  }

  .plus15_2 {
    left: 62.5%;
    right: 33.59%;
    top: 72.45%;
    bottom: 22.06%;
  }

  .plus16_1 {
    left: 30.86%;
    right: 65.23%;
    top: 26.8%;
    bottom: 69.97%;
  }

  .plus16_2 {
    left: 44.92%;
    right: 51.17%;
    top: 56.63%;
    bottom: 40.13%;
  }

  .plus17_1 {
    left: 30.23%;
    right: 65.86%;
    top: 33.28%;
    bottom: 62.79%;
  }

  .plus17_2 {
    left: 78.91%;
    right: 17.19%;
    top: 62.24%;
    bottom: 33.83%;
  }

  .plus18_1 {
    left: 39.53%;
    top: 18.82%;
  }

  .plus18_2 {
    left: 81.8%;
    top: 56.62%;
  }
  // Plus Icons End (Desktop)

  // Age Block Images (Desktop)
  .ageBlockAge {
    margin: 0;
    position: absolute;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
    z-index: 11;
    left: 82.81%;
  }
  .ageBlockAge0 {
    width: 8.93%;
    top: 43.16%;
  }
  .ageBlockAge1 {
    width: 5.64%;
    top: 32.35%;
  }
  .ageBlockAge2 {
    width: 7.21%;
    top: 33.3%;
    z-index: 0 !important;
  }
  .ageBlockAge3 {
    width: 7.21%;
    top: 34.22%;
    z-index: 0 !important;
  }
  .ageBlockAge4 {
    width: 7.69%;
    top: 36.72%;
  }
  .ageBlockAge5 {
    width: 7.21%;
    top: 38.44%;
  }
  .ageBlockAge6 {
    width: 7.37%;
    top: 37.73%;
  }
  .ageBlockAge7 {
    width: 7.33%;
    top: 43.16%;
  }
  .ageBlockAge8 {
    width: 7.37%;
    top: 43.16%;
  }
  .ageBlockAge9 {
    width: 7.37%;
    top: 40.79%;
  }
  .ageBlockAge10 {
    width: 13.69%;
    top: 23.25%;
  }
  .ageBlockAge11 {
    width: 10.3%;
    top: 32.4%;
  }
  .ageBlockAge12 {
    width: 11.81%;
    top: 25.22%;
  }
  .ageBlockAge13 {
    width: 11.87%;
    top: 39.32%;
  }
  .ageBlockAge14 {
    width: 12.14%;
    top: 26.4%;
  }
  .ageBlockAge15 {
    width: 12.22%;
    top: 40.83%;
  }
  .ageBlockAge16 {
    width: 11.99%;
    top: 24.08%;
    z-index: 12 !important;
  }
  .ageBlockAge17 {
    width: 12.02%;
    top: 24.08%;
  }
  .ageBlockAge18 {
    width: 12.07%;
    top: 31.39%;
  }
  // Age Block Images End (Desktop)
}
